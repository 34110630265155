* {
  padding: 0;
  margin: 0;
}

.slide {
  max-width: 100%;
  max-height: 100%;
}
.active {
  display: block;
}

.inactive {
  display: none;
}

